/* Header */
header {
  background-color: rgb(21, 54, 76) !important;
  padding: 10px 0;
  transform: none !important;
  visibility: unset !important;
}
a {
  font-family: 'sofia-pro';
  cursor: pointer;
}
h2,
h3 {
  font-family: 'sofia-pro';
}
/* common */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
table td,
table th {
  border-bottom: 1px #c4c4c4 solid;
  border-collapse: collapse;
  padding: 16px;
  border-spacing: 0;
}
table th {
  text-align: left;
}
.bold {
  font-weight: bold;
}
/* table td:last-child {border-bottom: none;} */

.indent {
  padding: 0 0 0 35px;
  margin: -16px -16px -17px;
  border-bottom: 2px black solid;
}

.catHeader {
  background-color: #7b88a8;
}

/* Tool Action Table */
.inner-table {
  margin: -6px -16px -6px -16px;
  padding-left: 35px;
}

.main-table .toolActionHeader th {
  background-color: #e9e9e9;
}
.main-table .toolActionHeader th.gridicon,
.main-table .toolActionHeader td.gridicon {
  width: 50px;
  text-align: center;
}

.tooltitle {
  white-space: nowrap;
}

/* Material Overrides */
.MuiAccordion-rounded:last-child,
.MuiAccordion-rounded:last-child {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.toolActionHeader .MuiAccordionSummary-root {
  padding-left: 0;
}
.toolActionHeader .MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px;
}
.toolActionHeader .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0;
}
.toolActionHeader .MuiAccordionDetails-root {
  background: white;
  padding: 0;
}

.toolActionHeader .MuiAccordionDetails-root table th {
  background-color: #f4f4f4;
}
.toolActionHeader .MuiAccordionDetails-root table tr:last-child td {
  border-bottom: none;
}

.MuiAccordionSummary-content {
  font-weight: bold;
  color: #7b88a8;
}
.MuiAccordionSummary-content:before {
  border: 2px #7b88a8 solid;
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  margin-right: 10px;
  transform: rotate(45deg);
  align-self: center;
  border-width: 2px 2px 0px 0;
  position: absolute;
  left: -20px;
}
.toolActionHeader .MuiAccordionSummary-content.Mui-expanded:before {
  transform: rotate(135deg);
}

.main-table th {
  background-color: 'white';
  padding-bottom: 12px;
  padding-top: 12px;
  font-weight: 700;
  border-bottom: 4px rgb(21, 54, 76) solid;
}

.simple-form {
  padding: 20px;
  text-align: left;
}
.simple-form .MuiCardContent-root {
  padding: 0;
}
.ra-input h2 {
  width: auto;
  margin: 0 0 20px 10px;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px #ccc solid;
}

.form-row {
  display: flex;
  width: auto !important;
}

.form-row .MuiFilledInput-root {
  display: block;
  flex: 1;
  border-radius: 0;
  background-color: transparent;
}

.flex {
  border: 1px red solid;
}

.ra-input {
  width: auto;
}
.MuiSelect-selectMenu {
  text-align: left;
}
.form-row .MuiFormControl-marginDense {
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.MuiFilledInput-input {
  padding-left: 2px !important;
}
.form-row .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense,
.group .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense,
.questions-list
  .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
  transform: translate(0px, 7px) scale(0.75);
}

.children {
  width: auto !important;
}
.children .MuiFormControl-root {
  width: 100%;
  margin: 0 5px;
}
/* .children ul { border: 1px red solid;}*/
.children ul li:last-child {
  padding: 15px 0;
}
.children ul li:last-child span {
  padding: 0;
}

/* .children section { margin-bottom: 20px;} */
.children .group {
  width: 100%;
  display: flex;
  border-bottom: 1px #ccc solid;
  margin-bottom: 20px;
  padding-top: 20px;
}

.children .group li ul li {
  width: 100%;
  display: flex;
  border-bottom: 1px blue solid;
  margin-bottom: 20px;
}

.children .MuiTypography-body1 {
  position: relative;
  padding-top: 32px;
}
.children .MuiTypography-body1:before {
  content: '';
  position: absolute;
  left: 50%;
  height: calc(100% - 90px);
  width: 1px;
  border-left: 2px dotted #ccc;
  top: 70px;
  margin-left: -1px;
}

/* .children div[class^="RaFormInput"] { background: red; width: auto !important;} */

.children .questions-list li {
  padding: 25px 0;
}
.children .questions-list li:last-child {
  padding: 25px 0;
}
.children .questions-list li section {
  margin-bottom: -25px;
}
.children .questions-list li:last-child span {
  padding-top: 0 !important;
}

section + span {
  margin-left: 10px;
  padding-top: 15px !important;
}

/* Users  */
.searchWrapper {
}
.searchWrapper .MuiInputBase-root {
  width: 320px;
  background: transparent;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.searchWrapper .MuiInputBase-root:hover,
.searchWrapper .MuiInputBase-root.Mui-focused {
  background: transparent;
}
.team-fliter-wrap .searchWrapper {
  margin-top: -11px !important;
}
.team-fliter-wrap .searchWrapper .MuiFormControl-marginDense {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
/* Layout */

main {
  position: relative;
  top: 20px;
}

.MuiDrawer-root {
  margin-right: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
  /* sticky HEader */
  position: sticky;
  height: 100%;
  top: 68px;
}

.MuiMenuItem-root {
  padding: 16px !important;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid !important;
}

@media (min-width: 600px) {
  .MuiMenuItem-root {
    padding: 16px;
  }
}
.MuiMenuItem-root {
  min-height: 48px;
}

.RaMenuItemLink-active-31,
.RaMenuItemLink-active-31 .MuiListItemIcon-root {
  color: black !important;
  font-weight: 500 !important;
}

/* EDit Audit */
.editAudit {
  padding: 10px;
  text-align: left;
}
.editAudit .MuiCardContent-root {
  display: flex;
  align-items: flex-start;
}

.editAudit .MuiFormControl-root {
  margin: 0;
}
.editAudit .RaLabeled-value-40 {
  padding: 0;
}
.editAudit .firstValue {
  padding: 10px 10px 10px 0;
}

.editAudit .firstValue label {
  transform: translate(0px, -5px) scale(0.75);
}

.p10 {
  padding: 10px;
}
.editAudit .toolHead {
  font-weight: bold;
  border-bottom: 1px #ccc solid;
  padding-bottom: 8px;
  margin-bottom: 8px;
  min-width: 300px;
}
.rowWrap {
  padding: 0 0 10px 0;
  border-bottom: 1px #ccc solid;
  margin-bottom: 10px;
}
.has-flex {
  display: flex;
}
.is-flex {
  flex: 1;
}
.w50p {
  width: 50%;
}
.selectWrapper {
  margin-top: 10px !important;
}

.createQuestion {
  padding: 0px 30px 25px;
}
.createQuestion .ra-input {
  padding-right: 20px;
}
.createQuestion .MuiCardContent-root {
  display: flex;
}
.createQuestion .MuiTextField-root {
  width: 100%;
  margin: 12px 0;
}

.createQuestion .questionType {
  width: 500px;
}

.questionType .MuiFormControl-marginNormal {
  margin-top: 0;
}

.questionType .MuiFormHelperText-root {
  display: none;
}

.createQuestion
  .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
  transform: translate(0px, 5px) scale(0.75);
}

.thumbNailView img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
/* .main-table td{ padding:0;} */
/* .main-table td span{ padding: 16px; display: inline-block} */
/* .MuiTableCell-sizeSmall { padding: 0 !important;} */

/* Added by Prashanth */

.styled-form2 .MuiCardContent-root {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding-top: 0 !important;
}
.text-display {
  padding-top: 20px !important;
}

.intake-wrapper {
  max-height: 100vh;
  box-sizing: border-box;
  overflow: auto;
}

.intake-form {
  display: grid;
  grid-template-columns: 1fr;
  /* grid-gap: 1rem; */
  /* border:1px red solid; */
  width: auto !important;
}

.MuiFormControl-root label,
.MuiFormLabel-root.Mui-disabled {
  position: static;
  transform: none !important;
  margin-bottom: 10px;
  line-height: 22px;
  color: #000 !important;
  font-weight: 600;
}

.MuiFilledInput-input {
  padding: 0 !important;
}
.MuiFormControl-root .MuiInputBase-root {
  padding: 15px;
  border: 1px #ccc solid;
  background-color: transparent;
  border-radius: 4px;
}
.MuiFormControl-root .MuiInputBase-root.Mui-disabled::after,
.MuiFormControl-root .MuiInputBase-root.Mui-disabled::before {
  display: none;
}
.intake-width .MuiCardContent-root {
  padding-top: 0 !important;
}
.intake-width .MuiFilledInput-underline:before {
  border-bottom: none;
}
.intake-width {
  width: 60vw;
  box-sizing: border-box;
}
.intake-width .simple-form {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 30px 30px 60px;
  position: relative;
}
.intake-width .MuiToolbar-root,
.gift-create .simple-form .MuiToolbar-root {
  position: fixed !important;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  z-index: 1;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  border-top: 1px white solid;
}
.MuiDrawer-paperAnchorRight .intake-width .MuiToolbar-root {
  left: unset;
  width: 56%;
}
.MuiFormControl-root .MuiInputBase-root {
  padding: 0;
  min-height: 51px;
  height: auto;
}
.simple-form .MuiFilledInput-input {
  padding: 15px !important;
  background-color: #fff !important;
  border-radius: 4px;
  font-weight: 300;
}
.MuiInputBase-root.Mui-disabled .MuiFilledInput-input {
  padding-left: 0 !important;
  background-color: transparent !important;
}
.simple-form .MuiSelect-selectMenu {
  height: 100%;
  /* max-height: 51px; */
  box-sizing: border-box;
  padding: 15px !important;
}
.promo-wrap .simple-form .MuiSelect-selectMenu {
  padding: 7px !important;
}
.team-edit .MuiFilledInput-multiline.MuiFilledInput-marginDense {
  padding-top: 0px;
  padding-bottom: 0;
  height: 100%;
}
.MuiFilledInput-root.Mui-disabled {
  background-color: transparent !important;
  border: 0;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 300;
}
.MuiFilledInput-root.Mui-disabled .MuiSelect-iconFilled {
  display: none;
}
.editable-input .MuiFilledInput-root.Mui-disabled {
  border: 1px solid #ccc;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.editable-input .MuiFilledInput-root.Mui-disabled .MuiFilledInput-input {
  padding-left: 15px !important;
}
.text-indent .MuiSelect-root span {
  padding-left: 10px;
}

.note {
  padding: 30px;
}
.note div {
  margin-bottom: 20px;
}
.note h2 {
  margin: 0 0 30px;
}
.note h3 {
  margin: 0 0 10px;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  width: 100% !important;
  align-items: flex-end;
}
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  width: 100% !important;
  align-items: flex-end;
}

.gap-40 {
  grid-gap: 40px;
}

.sub-headers-with-icon {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.sub-headers-with-icon .sub-headers {
  margin-bottom: 0px;
  margin-top: 0px;
  margin-right: 40px;
}

.sub-headers {
  margin-bottom: 10px;
  margin-top: 20px;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  width: fit-content !important;
  font-family: 'sofia-pro';
}

.sub-headers.margin-left {
  margin-left: 25px;
}
/* .mb63 {
  margin-bottom: 63px;
} */
.MuiButton-containedPrimary {
  background: rgb(232, 208, 205) !important;
  color: rgb(21, 54, 76) !important;
  font-weight: 600 !important;
  letter-spacing: 1.66px !important;
}
.show-tab {
  border-left: 1px solid rgb(224, 233, 239) !important;
  border-right: 1px solid rgb(224, 233, 239) !important;
  font-weight: 600 !important;
}
.show-tab:last-child {
  border-right: 0px !important;
}
.MuiTab-textColorInherit.Mui-selected {
  background-color: rgb(224, 233, 239);
  color: rgb(21, 54, 76);
}
.MuiTabs-indicator {
  background-color: rgb(21, 54, 76) !important;
}

.RaLogin-main-1 {
  background-color: rgb(21, 54, 76);
  background-image: none !important;
  justify-content: center !important;
}

.RaLogin-card-2 {
  padding: 15px !important;
  padding-bottom: 25px !important;
  margin-top: unset !important;
}

.RaLogin-avatar-3 {
  display: none !important;
}
.custom-btn {
  background: #fff !important;
  color: rgb(21, 54, 76) !important;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  /* transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: 1px solid rgb(21, 54, 76) !important;
  cursor: pointer;
}

.slide-right .MuiToolbar-root {
  flex-direction: column;
  padding: 24px;
}
.slide-right .MuiToolbar-root button {
  width: 100% !important;
}
.slide-right .MuiToolbar-root .custom-btn {
  max-height: 36px;
}
.slide-right .MuiFormControl-root .MuiInputBase-root {
  padding: 0 15px !important;
}

.MuiFilledInput-multiline.MuiFilledInput-marginDense {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.promo-wrap,
.team-edit {
  margin-bottom: -24px;
}

#main-content::-webkit-scrollbar {
  display: none;
}
.MuiFilledInput-underline:after {
  border-bottom-color: rgb(21, 54, 76) !important;
}

/* Login */

.page-wrap {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(21, 54, 76);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.login-container {
  max-width: 380px;
  width: 90%;
  margin-top: -80px;
}
.login-container .MuiToolbar-root {
  background-color: transparent;
  padding: 0 0 16px 0;
  min-height: auto;
}
.login-wrap {
  background: white;
  border-radius: 6px;
  padding: 25px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.logo-wrapper {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
}
.logo-wrapper img {
  max-height: 40px;
  display: inline-block;
}
.login-container .simple-form {
  padding: 0;
}
.login-container .MuiFormControl-root {
  width: 100%;
}
.text-blue-600 {
  cursor: pointer;
  color: rgb(21, 54, 76);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-family: 'sofia-pro';
  width: fit-content;
}

.text-xl {
  font-family: 'sofia-pro';
  line-height: 100%;
  font-weight: 500;
  text-align: center;
}
.login-btn {
  background: rgb(232, 208, 205);
  color: rgb(21, 54, 76);
  font-weight: 600;
  letter-spacing: 1.66px;
  padding: 10px 20px;
  margin: auto;
}
.login-btn:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.content-toggle {
  cursor: pointer;
  margin-top: 20px;
  border-bottom: 2px solid #000 !important;
  padding-bottom: 3px !important;
  width: fit-content !important;
}

.full-col {
  grid-column-start: 1;
  grid-column-end: 4;
}

.icon-wrapper {
  display: inline-block;
  padding: 3px;
  margin-right: 10px;
  font-size: 12px;
}

.more-info {
  border-radius: 6px;
  padding: 20px;
  background-color: rgb(224, 233, 239);
  overflow: hidden;
  height: auto;
  transition: all 100ms linear;
  width: 100% !important;
  box-sizing: border-box;
  margin-top: 20px;
}

.more-info h2 {
  width: 100% !important;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  font-size: 18px;
}

.more-info .emergency-info {
  padding-top: 30px;
}

.accordian-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow {
  width: 12px;
  height: 12px;
  transition: all 10ms linear;
  transform: rotate(135deg);
  transform-origin: center;
}

.arrow.up {
  border-bottom: 0px solid;
  border-left: 0px solid;
  border-top: 2px solid;
  border-right: 2px solid;
  position: relative;
  top: -4px;
}

.arrow.down {
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-top: 0px solid;
  border-right: 0px solid;
  position: relative;
  top: 4px;
}

header h6 + button,
header h6 + div {
  display: none !important;
}

/* Loading Indicator */
.plain-loading h1 {
  display: none;
}
.plain-loading {
  height: auto !important;
  margin-top: unset !important;
  padding: 20px 0;
}

.custom-flex {
  flex-direction: column;
  background-color: transparent !important;
  padding: 0 !important;
}
.custom-flex button {
  width: 100% !important;
}

.team-fliter-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}
.team-fliter-wrap .searchWrapper .MuiFormControl {
  margin-top: 34px;
}
.refunded-disable.Mui-disabled {
  pointer-events: visible !important;
  cursor: not-allowed !important;
  background-color: #e4e6e8 !important;
}
.main-table .searchWrapper {
  margin-top: 0;
}
.main-table .searchWrapper .RaSearchInput-input-68 {
  margin-top: 0;
}
@media only screen and (max-width: 600px) {
  .login-wrap .simple-form .MuiToolbar-root {
    left: unset;
    right: unset;
    bottom: unset;
  }
}

.custom-table-view {
  display: grid;
  width: 100% !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
}

.custom-table-view .MuiFormControl-marginDense {
  margin: 0px;
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: flex-start;
  padding: 10px 0px;
}

.custom-table-view
  .MuiFormControl-marginDense
  .MuiInputLabel-filled.MuiInputLabel-shrink {
  margin: 0px;
}

.custom-table-view .MuiFormControl-marginDense .MuiFormHelperText-contained {
  display: none;
}

.custom-table-view
  .MuiFormControl-marginDense
  .MuiFilledInput-root.Mui-disabled {
  min-height: auto;
  line-height: unset;
}

.custom-table-view
  .MuiFormControl-marginDense
  .MuiFilledInput-root.Mui-disabled
  input {
  padding: 0px !important;
  min-height: 22px;
}

.custom-table-view
  .MuiFormControl-marginDense
  .MuiFilledInput-root.Mui-disabled
  textarea {
  padding: 0px !important;
  min-height: 22px;
}

.custom-table-view
  .MuiFormControl-marginDense
  .MuiFilledInput-root.Mui-disabled
  .MuiSelect-select {
  padding: 0px !important;
  min-height: 22px;
  white-space: normal;
}

.gift-actions {
  position: absolute;
  top: 30px;
  right: 0px;
  z-index: 2;
}

.gift-actions a {
  background: rgb(232, 208, 205);
  color: rgb(21, 54, 76);
  font-weight: 600;
  letter-spacing: 1.66px;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  margin-left: 10px;
}

.custom-table,
.MuiDrawer-paper {
  font-family: 'sofia-pro', cursive;
}

table.custom-table,
.custom-table th,
.custom-table td {
  border: 0px;
}

.custom-table tr:last-child {
  background-color: #e0e9ef;
}

.custom-table tr:last-child td {
  font-weight: bold;
}

.custom-table td {
  padding: 10px;
}

.custom-table td:last-child {
  text-align: right;
}

.previews .MuiButtonBase-root.MuiIconButton-root {
  display: none;
}

.report-fliter-wrap {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}

.stripe-wrapper {
  margin-bottom: 20px;
  width: 100%;
}

.stripe-inputs-new {
  padding: 15px !important;
  background-color: #fff !important;
  border-radius: 4px;
  font-weight: 300;
  border: 1px #ccc solid;
  outline: 0px;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.stripe-inputs-new:focus {
  border: 1px #15364c solid;
  outline: 1px #15364c solid;
}

.stripe-inputs .StripeElement {
  padding: 15px !important;
  background-color: #fff !important;
  border-radius: 4px;
  font-weight: 300;
  border: 1px #ccc solid;
  border-radius: 4px;
  width: -webkit-fill-available;
  outline: 0px;
}

.stripe-inputs .StripeElement.StripeElement--focus {
  border: 1px #15364c solid;
  outline: 1px #15364c solid;
}

.stripe-label {
  margin-bottom: 10px;
  line-height: 22px;
  color: #000 !important;
  font-weight: 600;
}
